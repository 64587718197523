<template>
	<div id="formData">
		<webHeader></webHeader>
		<div class="bormBox">
			<img src="../assets/images/img.png" alt="">
			<div class="formBox">
				<ul>
					<b>
						<i class="el-icon-check"></i>
					</b>
					<li :class="active == 1 ? 'active' : ''" @click="active= 1">上传文档</li>
					<el-divider direction="vertical"></el-divider>
					<li :class="active == 2 ? 'active' : ''" @click="active= 2">抓取网页</li>
				</ul>

				<div class="form" v-if="active == 1">
					<div class="row">
						<p class="tit">您所在的类脑单元位置</p>
						<div class="val">医学专业类脑---孙乾</div>
					</div>

					<div class="row">
						<p class="tit">选择您需要上传的文件</p>

						<el-upload class="upload" :action="`${baseUrl}/chat/doctor/uploadFile`"
							:on-success="handleSuccess" :on-remove="handleRemove" :before-remove="beforeRemove" multiple
							:limit="maxFile" :on-exceed="handleExceed" :file-list="fileList">
							<el-input placeholder="点击上传" class="input-with-select" readonly>
								<el-button slot="append" icon="el-icon-upload">选择文件</el-button>
							</el-input>
							<div slot="tip" class="el-upload__tip">
								<p>支持文档识别、图片识别、音视频识别。</p>
								<p>文档支持30MB 以内，图片支持 30MB 以内，音视频支持 100MB 以内。</p>
								<p>图片及音视频上传后会自动解析成文字存储并学习。</p>
								<p>文档内容中表格和图片可能存在无法学习的情况。</p>
							</div>
						</el-upload>
					</div>

					<el-button class="submitBtn" @click="submit()">提交</el-button>

				</div>
				<div class="form" v-if="active == 2">
					<div class="row">
						<p class="tit">您所在的类脑单元位置</p>
						<div class="val">医学专业类脑---孙乾</div>
						<p class="tip">您可以指定网页地址，但由于对象网络的安全策略，部分网页内容可能无法通过技术手段完成内容抓取</p>
					</div>

					<div class="row" v-for="(item,i) in urls" :key="i">
						<el-input placeholder="请在此输入网址" v-model="item.url">
							<template slot="prepend">Http://</template>
						</el-input>
					</div>

					<el-link type="primary" @click="pushUrl" :underline="false">+点击添加网络地址</el-link>

					<el-button class="submitBtn" @click="submit()">提交</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		submitFile,
		uploadUrl
	} from '@/request/api.js';
	import baseUrl from '@/request/config.js';
	import webHeader from '@/components/header.vue'
	export default {
		name: 'formData',
		components: {
			webHeader
		},
		data() {
			return {
				baseUrl: '',
				active: 1,
				maxFile: 12,
				fileList: [],
				// {
				// 	name: 'food2.jpeg',
				// 	url: 'h'
				// }
				urls: [{
					url: ''
				}]
			}
		},
		created() {
			this.baseUrl = baseUrl;
		},
		mounted() {},
		methods: {
			pushUrl() {
				this.urls.push({
					url: ''
				});
			},

			submit() {
				if (this.active == 1) {
					this.addFile();
				} else {
					this.addUrl();
				}
			},

			addFile() {
				let data = this.fileList.map(item => item.response);

				if (data.length == 0) {
					this.$confirm('请上传文档', '提示', {
						confirmButtonText: '确定',
						showCancelButton: false,
						type: 'warning'
					});
				} else {
					submitFile(data).then(res => {
						if (res.code == 200) {
							this.$router.push({
								name: 'Result',
								query: {
									act: 1
								}
							});
						}
					});
				}
			},

			addUrl() {
				let data = this.urls.map(item => item.url);
				if (data[0]) {
					uploadUrl(data).then(res => {
						if (res.code == 200) {
							this.$router.push({
								name: 'Result',
								query: {
									act: 2
								}
							})
						}
					});
				} else {
					this.$confirm('请添加链接', '提示', {
						confirmButtonText: '确定',
						showCancelButton: false,
						type: 'warning'
					});
				}
			},

			handleSuccess(res, file, fileList) {
				console.log(res, file, fileList);
				this.fileList = fileList
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handleExceed(files, fileList) {
				this.$message.warning(
					`当前限制选择 ${this.maxFile} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			beforeRemove(file) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			}
		}
	}
</script>

<style scoped lang="less">
	#formData {
		width: 100vw;
		height: 100vh;
		overflow: hidden;

		.bormBox {
			width: 100%;
			height: 100%;
			background: #0065FF;
			display: flex;
			align-items: center;
			justify-content: center;


			img {
				height: 500px;
			}

			.formBox {
				width: 710px;
				height: 600px;
				background-color: #343549;
				border-radius: 15px;
				padding: 30px;
				overflow: hidden;
				overflow-y: scroll;
				box-shadow: 0 0 30px rgba(0, 0, 0, .2);
				border: 1px solid #343549;

				ul {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					width: 100%;

					li {
						color: #858383;
						transition: all .3s;
						font-size: 20px;
						cursor: pointer;
					}

					b {
						height: 40px;
						width: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						border: 1px solid #262837;
						border-radius: 50%;
						margin-right: 20px;

						i {
							font-size: 16px;
							padding: 3px;
							color: #CB566D;
							border: 2px solid #CB566D;
							border-radius: 10px;
						}
					}


					.el-divider {
						margin: 0 40px;
						height: 30px;
						width: 3px;
					}

					.active {
						color: #fff;
					}
				}
			}

			.form {
				flex: 1;
				width: 100%;
				padding: 20px 20px 20px 60px;

				.submitBtn {
					height: 46px;
					background-color: #2F2EE9;
					color: #fff;
					font-size: 16px;
					border: none;
					width: 100%;
					margin-top: 30px;
				}

				.row {
					margin-bottom: 20px;

					.tip {
						font-size: 12px;
						color: #ccc;
						text-align: justify;
						margin-top: 10px;
					}

					&:first-child {
						margin-bottom: 30px;
					}

					.tit {
						text-align: justify;
						margin-bottom: 16px;
						color: #fff;
					}

					/deep/.el-input {
						background-color: #262837;
						border-radius: 10px;
						margin: 0;

						.el-input-group__prepend {
							background-color: transparent;
							border: none;
						}

						.el-input__inner {
							border: none;
							background-color: transparent;
							color: #fff;
						}
					}

					.val {
						height: 46px;
						padding: 10px;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						color: #fff;
						font-size: 16px;
						background-color: #2F3044;
						border-radius: 10px;
					}

					/deep/.upload {
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;

						* {
							color: #fff;
						}

						.el-upload {
							width: 100%;
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							justify-content: center;
						}

						.el-input-group {
							background-color: #2F3044;
							border-radius: 10px;

							.el-input__inner {
								background-color: transparent;
								border: none;
							}

							.el-input-group__append {
								background-color: #0065FF;
								border: none;
							}
						}

						.el-upload-list {
							width: 100%;
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							justify-content: center;

							.el-upload-list__item {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: flex-start;
								border-bottom: #eee;
								overflow: hidden;

								&:hover {
									background-color: rgba(255, 255, 255, .1);
								}
							}
						}

						.el-upload__tip {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							justify-content: center;

							p {
								margin-top: 4px;
								text-align: left;
								color: #ccc;
							}
						}
					}

				}

				/deep/.el-link {
					width: 100%;
					display: flex;
					justify-content: flex-start;

					.el-link--inner {
						color: #219D98;
					}
				}
			}
		}
	}
</style>